"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useUniqueInlineId", {
  enumerable: true,
  get: function get() {
    return _useUniqueInlineId["default"];
  }
});
Object.defineProperty(exports, "Provider", {
  enumerable: true,
  get: function get() {
    return _UniqueIdGeneratorProvider["default"];
  }
});

var _useUniqueInlineId = _interopRequireDefault(require("./useUniqueInlineId"));

var _UniqueIdGeneratorProvider = _interopRequireDefault(require("./UniqueIdGeneratorProvider"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }